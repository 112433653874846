import { getEmojiDataFromNative } from "emoji-mart";
import emojiRegex from "emoji-regex";

/**
 * Get emoji image
 * @param {Object} emoji Emoji data object
 * @return {`<img src="${string}" alt="${*}" data-native="${*}"/>`}
 */
export const getEmojiImage = (emoji) => {
  return `<img src="${getEmojiImgSrc(emoji)}" alt="${
    emoji.native
  }" data-native="${emoji.native}" class="emojione"/>`;
};

/**
 * Get emoji sorce image
 * @param {Object} emoji Emoji data
 * @return {"https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/64/null.png"}
 */
const getEmojiImgSrc = (emoji) => {
  return `https://cdn.jsdelivr.net/npm/emoji-datasource-apple@14.0.0/img/apple/64/${emoji.unified}.png`;
};

/**
 * Replace native emoji in text by images
 * @param {string} text Text for replace
 * @return {Promise<*>}
 */
export const emojiToImage = async (text) => {
  try {
    const regex = emojiRegex();
    let iteration = 0;

    let replacedText = text.replace(regex, () => {
      const replaceWith = `{{emoji${iteration}}`;
      iteration++;

      return replaceWith;
    });

    iteration = 0;

    for (const match of text.matchAll(regex)) {
      if (match[0]) {
        let unified = null;
        const emoji = await getEmojiDataFromNative(match[0]).catch((error) => {
          console.error(error.toString());
          return null;
        });

        try {
          unified = [...match[0]]
            .map((e) => e.codePointAt(0).toString(16))
            .join(`-`);
        } catch (e) {
          console.error(e.toString());
        }

        if (emoji?.unified && unified !== emoji?.unified) {
          unified = emoji?.unified;
        }

        if (unified) {
          const emoji = {
            native: match[0],
            unified: unified,
          };

          replacedText = replacedText.replace(
            new RegExp(`{{emoji${iteration}}`, "ig"),
            getEmojiImage(emoji)
          );
        }
      } else {
        replacedText = replacedText.replace(
          new RegExp(`{{emoji${iteration}}`, "ig"),
          ""
        );
      }

      iteration++;
    }

    return replacedText;
  } catch (e) {
    console.error(e.toString());
    return text;
  }
};

/**
 * Check text on emoji
 *
 * @param {string} text Simple string text
 * @return {boolean}
 */
export function hasTextEmoji(text) {
  if (!text || text.length === 0) {
    return false;
  }

  try {
    const emoji = text.match(
      /\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]/g
    );

    if (emoji && emoji.length > 0) {
      return true;
    }
  } catch (e) {
    console.error(e.toString());
  }

  return false;
}
