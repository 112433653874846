export const PAYMENT_SEON_SESSION_ID = "seonSessionId";

export const paymentMethod = {
  OneClick: "OneClick",
  GPay: "GPay",
  ApplePay: "ApplePay",
};

export const paymentCBType = {
  success: "success",
  decline: "decline",
  redirect: "redirect",
};

export const paymentDeclineReason = {
  PERMANENT_CARD_BLOCK: 1,
  INTERNAL_CARD_BLOCK: 2,
  GENERAL: 3,
  INSUFFICIENT_FUNDS: 4,
  CUSTOMER_ABORT: 5,
  AUTH_3D_FAIL: 6,
  INVALID_CARD_DATA: 7,
};

export const DISCOUNT_PERCENT = 1;
export const DISCOUNT_MONEY = 2;

export const INITIAL_OFFER_DISCOUNT = 1;
export const REGULAR_PRICE_DISCOUNT = 2;
export const ONE_TIME_OFFER_DISCOUNT = 3;
export const HAPPY_BIRTHDAY_DISCOUNT = 4;
